import React from 'react'
import {Link} from 'react-router-dom'
import Project1 from '../components/img/screencapture-alexagamer-2023-10-28-16_31_09.png'
import './Portfolio.css'
import Project2 from '../components/img/project2.png'
import Project3 from '../components/img/project3.png'
import Project4 from '../components/img/project4.png'
import Project5 from '../components/img/project5.png'
import Project6 from '../components/img/project6.png'
import Project7 from '../components/img/project7.png'
import Project8 from '../components/img/project8.png'
import Project9 from '../components/img/project9.png'
import Project10 from '../components/img/project10.png'

const Portfolio = () => {
  return (
    <div>
    <div class="breadcumb-area d-flex align-items-center">
	<div class="container">
		<div class="row d-flex align-items-center">
			<div class="col-lg-12">
				<div class="breadcumb-content">
					<h1> Our Portfolio </h1>
					<ul>
						<li><Link to="/">Home</Link></li>
						<li> Our Portfolio </li>
					</ul>
				</div>
			</div>
			<div class="britcam-shape">
				<div class="breadcumb-content upp">
					<ul>
						<li><Link to="/">Home</Link></li>
						<li> Our Portfolio </li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>

<div className='container text-center my-5'>
<h3>Impressive Portfolio of Recent Web and App Development Projects by Nazreen Consulting</h3>

</div>
<div className='container-fluid'>
    <div className='row'>

<div className='col-md-4 my-3'>
<div class="image-wrap">
    <h3 style={{textAlign:'center',margin:"10px",fontFamily:"inherit"}}>PROJECT - 1</h3>
    <a href="https://mantos.app/" target='_blank' >
        <img src={Project2}  style={{borderTop:"2px solid black"}}/>
    </a>
</div>
</div>
<div className='col-md-4 my-3'>
<div class="image-wrap">
    <h3 style={{textAlign:'center',margin:'10px',fontFamily:"inherit"}}>PROJECT - 2</h3>
    <a href="https://rsbali.com/" target='_blank'>
        <img src={Project4} />
    </a>
</div>
</div>
<div className='col-md-4 my-3'>
<div class="image-wrap">
    <h3 style={{textAlign:'center',margin:'10px',fontFamily:"inherit"}}>PROJECT - 3</h3>
    <a href="https://alruha.co/" target='_blank'>
        <img src={Project3} />
    </a>
</div>
</div>
<div className='col-md-4 my-3'>
<div class="image-wrap">
    <h3 style={{textAlign:'center',margin:'10px',fontFamily:"inherit"}}>PROJECT - 4</h3>
    <a href="https://dgoplumbing.org" target='_blank'>
        <img src={Project5} />
    </a>
</div>
</div>
<div className='col-md-4 my-3'>
<div class="image-wrap">
    <h3 style={{textAlign:'center',margin:'10px',fontFamily:"inherit"}}>PROJECT - 5</h3>
    <a href="https://angsolution.org/" target='_blank'>
        <img src={Project6} />
    </a>
</div>
</div>
<div className='col-md-4 my-3'>
<div class="image-wrap">
    <h3 style={{textAlign:'center',margin:'10px',fontFamily:"inherit"}}>PROJECT - 6</h3>
    <a href="http://trendyscreeniptv.com/" target='_blank'>
        <img src={Project7} />
    </a>
</div>
</div>
<div className='col-md-4 my-3'>
<div class="image-wrap">
    <h3 style={{textAlign:'center',margin:'10px',fontFamily:"inherit"}}>PROJECT - 7</h3>
    <a href="https://nazreenfoundation.com/" target='_blank'>
        <img src={Project8} />
    </a>
</div>
</div>
<div className='col-md-4 my-3'>
<div class="image-wrap">
    <h3 style={{textAlign:'center',margin:'10px',fontFamily:"inherit"}}>PROJECT - 8</h3>
    <a href="https://nazreenauto.com/" target='_blank'>
        <img src={Project9} />
    </a>
</div>
</div>
<div className='col-md-4 my-3'>
<div class="image-wrap">
    <h3 style={{textAlign:'center',margin:'10px',fontFamily:"inherit"}}>PROJECT - 6</h3>
    <a href="https://nazreeninsurance.com/" target='_blank'>
        <img src={Project10} />
    </a>
</div>
</div>



    </div>
    <div className='text-center my-5'>
<h3>And Many More..!!</h3>
</div>

</div>

</div>
  )
}

export default Portfolio